import {Component, Input, OnDestroy} from '@angular/core';
import {Container, Tab} from "../../../../model/layout.model";
import {LayoutElementUtil} from "../../../../service/layout/layout-element.service";
import {ScreenSizeObserverService} from "../../../../service/layout/screen-size-observer.service";
import {FileUploadComponent} from '../../../layout/element/file-upload/file-upload.component';
import {DatetimePickerComponent} from '../../../layout/element/datetime-picker/datetime-picker.component';
import {TextFieldComponent} from '../../../layout/element/text-field/text-field.component';
import {TerminalListComponent} from '../../../layout/element/terminal-list/terminal-list.component';
import {ComboBoxComponent} from '../../../layout/element/combo-box/combo-box.component';
import {VideoTextAreaComponent} from '../../../layout/element/video-text-area/video-text-area.component';
import {TextAreaComponent} from '../../../layout/element/text-area/text-area.component';
import {TerminalLabelComponent} from '../../../layout/element/terminal-label/terminal-label.component';
import {DividerComponent} from '../../../common/divider/divider.component';
import {NgFor, NgIf} from '@angular/common';
import {TextAreaContainerComponent} from "../../../layout/element/text-area-container/text-area-container.component";

@Component({
  selector: 'app-document-detail-elements',
  templateUrl: './document-detail-elements.component.html',
  styleUrls: ['./document-detail-elements.component.scss'],
  standalone: true,
  imports: [NgFor, NgIf, DividerComponent, TerminalLabelComponent, TextAreaComponent, VideoTextAreaComponent, ComboBoxComponent, TerminalListComponent, TextFieldComponent, DatetimePickerComponent, FileUploadComponent, TextAreaContainerComponent]
})
export class DocumentDetailElementsComponent implements OnDestroy {

  @Input() tab!: Tab;
  @Input() documentId?: string;

  constructor(public layoutElementUtil: LayoutElementUtil, private screenSizeObserver: ScreenSizeObserverService) {
  }

  public isMobileScreen() {
    return this.screenSizeObserver.isSmallScreen() || this.screenSizeObserver.isXSmallScreen();
  }

  ngOnDestroy(): void {
    this.screenSizeObserver.destroy();
  }

  sectionHasDivider(sectionContainer: Container) {
    const section = this.layoutElementUtil.asSection(sectionContainer);
    return section && section.showDivider;
  }

}
