<div class="textAreaContainer-container" style="margin-bottom: 50px">
  <div class="textAreaContainer" [style]="textAreaContainer.inlineStyle">
    <h3>{{ textAreaContainer.caption }}</h3>
    <div class="input-component-container">
      @for (textareas of textAreaContainer.containerList; track $index) {
        <div class="input-component" *ngIf="layoutElementUtil.isTextArea(textareas)">
          <app-text-area [textArea]="layoutElementUtil.asTextArea(textareas)" (diff)="computeCharcount($event)"></app-text-area>
        </div>

      }

    </div>
    <div class="input-component">
      <app-character-counter *ngIf="textAreaContainer.maxCharsWarning && textAreaContainer.maxChars"
                       [maxChars]="textAreaContainer.maxChars"
                       [maxCharsWarning]="textAreaContainer.maxCharsWarning"
                       [charSequenceLength]="charCount"></app-character-counter>
    </div>

    </div>

</div>
