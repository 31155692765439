import {Component, Input, OnDestroy, OnInit, signal} from '@angular/core';
import {TEXTFIELD_INPUT_TYPES, TextFieldInputType} from "../../../../model/constants";
import {TextField} from "../../../../model/layout.model";
import {CharacterCounterComponent} from '../character-counter/character-counter.component';
import {NgIf} from '@angular/common';
import {FormsModule} from '@angular/forms';
import {MatInputModule} from '@angular/material/input';
import {MatFormFieldModule} from '@angular/material/form-field';

@Component({
  selector: 'app-text-field',
  templateUrl: './text-field.component.html',
  styleUrls: ['./text-field.component.scss'],
  standalone: true,
  imports: [MatFormFieldModule, MatInputModule, FormsModule, NgIf, CharacterCounterComponent]
})
export class TextFieldComponent implements OnInit, OnDestroy {

  @Input() textField!: TextField;
  inputType: string = TextFieldInputType.TEXT;

  errorMessage;


  constructor() {
    this.errorMessage = 'Fehlerhafte Eingabe';
  }

  ngOnDestroy() {

  }

  ngOnInit(): void {
    // @ts-ignore
    this.inputType = TEXTFIELD_INPUT_TYPES.find(type => type === this.textField?.fieldType);
  }

  onKeyPress($event: any) {
    if (this.inputType === TextFieldInputType.NUMBER && this.textField.maxChars !== null && this.textField.maxChars !== undefined && this.textField.maxChars > 0) {
      this.textField.content = parseInt(this.textField.content.toString().slice(0, this.textField.maxChars - 1), 10);
    }
  }

  onInputChange($event: any) {
    this.textField.content = $event;
  }


  isInvalid() {

    let invalid = false;
    if (this.inputType === TextFieldInputType.EMAIL) {
      invalid = this.textField.content === null || this.textField.content === undefined || this.textField.content === ''
        || !(this.textField.content.toString().includes('@') && this.textField.content.toString().includes('.'));
      if (invalid)
        this.errorMessage = 'Keine valide E-Mail Addresse';
    } else if (this.inputType === TextFieldInputType.TEL) {
      invalid = this.textField.content === null || this.textField.content === undefined || this.textField.content === ''
        || !this.textField.content.toString().match(/^(?:\s*\d){6,13}$/);
      if (invalid)
        this.errorMessage = 'Keine valide Telefonnummer';
    }

    return invalid;
  }
}
