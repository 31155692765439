import {ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, ViewChild} from '@angular/core';
import {MatSortModule, Sort} from '@angular/material/sort';
import {DocumentHeader} from "../../../model/document.model";
import {FILTER_STATUS_ALLE, StatusCodes} from "../../../model/constants";
import {map, Subject, Subscription, takeUntil, timer} from "rxjs";
import {MatPaginator, MatPaginatorModule} from "@angular/material/paginator";
import {MatTableDataSource, MatTableModule} from "@angular/material/table";
import {FirebaseCloudMessagingService} from "../../../service/http/firebase-cloud-messaging.service";
import {ScreenSizeObserverService} from "../../../service/layout/screen-size-observer.service";
import {DocumentStore} from "../../../store/document.store";
import {RfaStore} from "../../../store/rfa/rfa.store";
import {MatSnackBar} from "@angular/material/snack-bar";
import {EventService} from "../../../service/event.service";
import {FirebaseMessagingModel} from "../../../model/firebase-messaging.model";
import {
  DocumentTableExpansionDetailComponent
} from './document-table-expansion-detail/document-table-expansion-detail.component';
import {DocumentTableMenuComponent} from './document-table-menu/document-table-menu.component';
import {RouterLink} from '@angular/router';
import {MatButtonModule} from '@angular/material/button';
import {MatIconModule} from '@angular/material/icon';
import {MatTooltipModule} from '@angular/material/tooltip';
import {DisplayMessageComponent} from '../../common/display-message/display-message.component';
import {LoadingSkeletonComponent} from '../../common/loading/loading-skeleton/loading-skeleton.component';
import {LoadingSpinnerComponent} from '../../common/loading/loading-spinner/loading-spinner.component';
import {AsyncPipe, DatePipe, NgIf} from '@angular/common';
import {
  DocumentTableActionHeaderComponent
} from './document-table-action-header/document-table-action-header.component';

@Component({
  selector: 'app-document-table',
  templateUrl: './document-table.component.html',
  styleUrls: ['./document-table.component.scss'],
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    DocumentTableActionHeaderComponent,
    NgIf,
    LoadingSpinnerComponent,
    LoadingSkeletonComponent,
    DisplayMessageComponent,
    MatTableModule,
    MatSortModule,
    MatTooltipModule,
    MatIconModule,
    MatButtonModule,
    RouterLink,
    DocumentTableMenuComponent,
    DocumentTableExpansionDetailComponent,
    MatPaginatorModule,
    AsyncPipe,
    DatePipe,
  ],
})
export class DocumentTableComponent implements OnDestroy {

  @ViewChild(MatPaginator) paginator!: MatPaginator;

  dataSource: MatTableDataSource<DocumentHeader> = new MatTableDataSource<DocumentHeader>();

  dateFormat = 'dd.MM.yy HH:mm'
  displayedColumns = ['id', 'published', 'unpublished', 'updated', 'status', 'workflow', 'edit', 'more', 'expand'];

  expandedElement: DocumentHeader | null = null;

  loadingTime = 0;
  readonly MAX_LOAD_TIME = 3;
  private destroyer$: Subject<boolean> = new Subject<boolean>();

  loading$;
  error$;
  documents$: Subscription | undefined;
  selectedRfa$: Subscription | undefined;

  selectedRfaKuerzel!: string;
  selectedDocument: DocumentHeader | undefined;

  constructor(public statusCodes: StatusCodes, private rfaStore: RfaStore, private snackbar: MatSnackBar,
              private firebaseCloudMessagingService: FirebaseCloudMessagingService, private changeRef: ChangeDetectorRef,
              private eventService: EventService, private screenSizeObserver: ScreenSizeObserverService,
              private documentStore: DocumentStore) {
    this.loading$ = this.documentStore.isLoading();
    this.error$ = this.documentStore.getError();

    this.eventService.getRefresh().pipe(takeUntil(this.destroyer$)).subscribe(() => {
      this.documentStore.init();
    });

    this.selectedRfa$ = this.rfaStore.getSelectedRfa().pipe(map((rundfunkanstalt) => {
      if (rundfunkanstalt) this.selectedRfaKuerzel = rundfunkanstalt.kuerzel;
    }), takeUntil(this.destroyer$)).subscribe();

    this.startTimer();
    this.loadDocuments();

    //TODO: Nur wenn in den environments ein flag gesetzt ist, dann wird der FCM Service initialisiert
    this.initFCMService();
  }

  private loadDocuments() {
    this.documents$ = this.documentStore.getDocuments()
      .pipe(takeUntil(this.destroyer$))
      .subscribe({
        next: (data) => {
          this.dataSource.data = data;
          this.changeRef.markForCheck();
          this.setFilterPredicate();

          let sort:Sort = {active:'id', direction: 'desc'};
          this.sortData(sort);
          this.dataSource.paginator = this.paginator;
        },
        error: (error) => {
          this.snackbar.open(`Fehler beim Laden der Daten ${error}`, 'OK');
        },
      })
  }

  // OnDestroy wird aufgerufen, wenn die Komponente zerstört/beendet wird
  // Hier wird der Timer gestoppt und der ScreenSizeObserver (Subjects und Observables schließen, um Datenlecks zu vermeiden)
  ngOnDestroy() {
    if (!this.destroyer$.closed) {
      this.destroyTimer();
    }
    this.screenSizeObserver.destroy();
  }

  // Startet den Timer, der die Ladezeit inkrementiert
  // Wenn die MAX_LOAD_TIME erreicht ist, dann wechselt im html der Ladekreis zum Skeleton
  private startTimer() {
    if (this.destroyer$.closed)
      this.destroyer$ = new Subject<boolean>();
    timer(0, 1000).pipe(takeUntil(this.destroyer$)).subscribe({
      next: () => this.loadingTime++
    })
  }

  private destroyTimer() {
    this.destroyer$.next(true);
    this.destroyer$.unsubscribe();
  }

  filterTable($filterEvent: string) {
    this.dataSource.filter = $filterEvent !== FILTER_STATUS_ALLE ? $filterEvent.valueOf().trim() : '';
  }

  // Setzt den Filter für die Tabelle
  // Logik: Wenn der Filter auf "Alle" steht, dann wird kein Filter gesetzt, ansonsten wird der Suchtext mit dem StatusKuerzel verglichen
  private setFilterPredicate() {
    this.dataSource.filterPredicate = (documentZeile: DocumentHeader, filter: string) => {
      const textToSearch = documentZeile.status.kuerzel.valueOf().toLowerCase();
      return textToSearch?.indexOf(filter.toLowerCase()) !== -1;
    }
  }

  // Sortierungslogik für die Tabelle
  sortData(sort: Sort) {

    this.dataSource.data = this.dataSource.data.sort((a, b) => {
      const isAsc = sort.direction === 'asc';
      console.log("SORT_DIRECTION: " + sort.direction);
      switch (sort.active) {
        case 'published':
          if (a.published === undefined || b.published === undefined) return 1;
          return this.compare(a.published, b.published, isAsc);
        case 'unpublished':
          if (a.unpublished === undefined || b.unpublished === undefined) return 1;
          return this.compare(a.unpublished, b.unpublished, isAsc);
        case 'id':
          if (a.documentId === undefined || b.documentId === undefined) return 1;

          let aa: number | null = this.parseIntegerFromString(a.documentId);
          let bb: number | null = this.parseIntegerFromString(b.documentId);

          if (aa !== null && bb !== null) {
            return this.compareN(aa, bb, isAsc);
          }

          return this.compare(a.documentId, b.documentId, isAsc);
        case 'updated':
          return this.compare(a.updated, b.updated, isAsc);
        case 'workflow':
          return this.compare(a.workflow.code, b.workflow.code, isAsc);
        case 'status':
          return this.compare(a.status.code, b.status.code, isAsc);
        default:
          return 0;
      }
    });
  }

  parseIntegerFromString(input: any): number | null {
    try {
      // Attempt to parse the integer from the input string
      const parsedInt = parseInt(input, 10);

      // Check if the parsing result is NaN (not a number)
      if (isNaN(parsedInt)) {
        return null;
        //throw new Error("Invalid input: Not a valid integer.");
      }

      return parsedInt;
    } catch (error: any) { // Specify the type of 'error' as 'any' or a more specific type
      // Handle the error and return null to indicate failure
      console.error(`Error: ${error.message}`);
      return null;
    }
  }

  private compareN(a: number, b: number, isAsc: boolean) {
    if(isAsc) {
      return a-b;
    } else {
      return b-a;
    }
  }

  private compare(a: number | string | Date, b: number | string | Date, isAsc: boolean) {
    if (a instanceof Date && b instanceof Date) {
      return (a.getTime() < b.getTime() ? -1 : 1) * (isAsc ? 1 : -1);
    }

    return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
  }

  private initFCMService() {
    //Der Messagingservice, der beim Firebase nach einem Token fragt und nach neuen PUSH messages lauscht
    this.firebaseCloudMessagingService.requestPermission();
    this.firebaseCloudMessagingService.listen();

    //Callback/Listener, der die Dokumente lädt, wenn eine Message eintrifft
    this.firebaseCloudMessagingService.asObservable()
      .pipe(takeUntil(this.destroyer$), map(payload => payload as FirebaseMessagingModel))
      .subscribe({
        next: (firebaseMessage) => {
          if (firebaseMessage) {
            console.log('Neue Push Message: ', firebaseMessage);
            if (firebaseMessage.data.updateCount > 0) {
              this.documentStore.changeStatusToPublished(firebaseMessage.data.ids.split(','));
              this.snackbar.open(`${firebaseMessage.data.updateCount} Datensätze wurden automatisch aktualisiert.`, 'OK', {
                verticalPosition: "bottom",
                horizontalPosition: "right",
              });
            }
          }
        },
        error: (err) => console.error("Error im callback des messagingservices: ", err)
      });
  }
}
