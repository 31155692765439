import {EventEmitter, Injectable} from '@angular/core';
import {
  ComboBox,
  Container,
  DateTimeTextField,
  FileUpload,
  Section,
  Tab,
  TerminalLabel,
  TerminalList,
  TextArea, TextAreaContainer,
  TextField,
  VideoTextArea
} from "../../model/layout.model";
import {TEXTFIELD_CONTAINER_TYPES} from "../../model/constants";
import {ComboBoxItem} from "../../model/document.model";
import {ScreenSizeObserverService} from "./screen-size-observer.service";

@Injectable({
  providedIn: 'root'
})
export class LayoutElementUtil {

  private readonly TEXT_AREA: string = 'TextArea';
  private readonly COMBO_BOX: string = 'ComboBox';
  private readonly DATE_LABEL: string = 'DateLabel';
  private readonly FILE_UPLOAD: string = 'FileUpload';
  private readonly TERMINAL_LIST: string = 'TerminalList';
  private readonly TERMINAL_LABEL: string = 'TerminalLabel';
  private readonly VIDEO_TEXT_AREA: string = 'VideoTextArea';
  private readonly DATETIME_PICKER: string = 'DateTimePicker';
  private readonly TEXT_AREA_DATE_CAPTION: string = 'TextAreaWithDateCaption';
  private readonly VIDEO_TEXT_AREA_DATE_CAPTION: string = 'VideoTextAreaWithDateCaption';
  private readonly TEXT_AREA_CONTAINER: string = 'TextAreaContainer';

  //Emitted das Event, wenn der Kopiervorgang abgeschlossen ist
  public copyComboBoxItemEvent: EventEmitter<boolean> = new EventEmitter<boolean>();

  constructor(private screenSizeObserver: ScreenSizeObserverService) {
  }

  /**
   * Bsp: width: 500px; height: 500px; background-color: red, sm: width: 200px; height: 200px; background-color: blue;
   */
  translateStyle(style: string) {
    let translatedStyle: string | undefined;
    if (style.includes('sm:') || style.includes('md:') || style.includes('lg:')) {
      let styleSections = style.split(',');
      if (this.screenSizeObserver.isXSmallScreen() || this.screenSizeObserver.isSmallScreen()) {
        translatedStyle = this.findStyle(styleSections, 'sm:');
      } else if (this.screenSizeObserver.isMediumScreen()) {
        translatedStyle = this.findStyle(styleSections, 'md:');
      } else if (this.screenSizeObserver.isLargeScreen() || this.screenSizeObserver.isExtraLargeScreen()) {
        translatedStyle = this.findStyle(styleSections, 'lg:');
      }
    }
    return translatedStyle ? translatedStyle : style;
  }

  findStyle(styleSections: string[], styleType: string) {
    const style = styleSections.find(section => section.trim().includes(styleType));
    return style ? style.split(styleType)[1] : undefined;
  }

  isTextField = (container: Container) => container && container.containerType && TEXTFIELD_CONTAINER_TYPES.includes(container.containerType.toLowerCase());

  isTerminalLabel = (container: Container) => container.containerType === this.TERMINAL_LABEL || container.containerType === this.DATE_LABEL;

  isTextArea = (container: Container) => container.containerType === this.TEXT_AREA || container.containerType === this.TEXT_AREA_DATE_CAPTION;

  isTextAreaContainer = (container: Container) => container.containerType === this.TEXT_AREA_CONTAINER;

  isTerminalList = (container: Container) => container.containerType === this.TERMINAL_LIST;

  isComboBox = (container: Container) => container.containerType === this.COMBO_BOX;

  isFileUpload = (container: Container) => container.containerType === this.FILE_UPLOAD;

  isVideoTextArea = (container: Container) => container.containerType === this.VIDEO_TEXT_AREA || container.containerType === this.VIDEO_TEXT_AREA_DATE_CAPTION;

  isDateTimePicker = (container: Container) => container.containerType === this.DATETIME_PICKER;
  asComboBox = (container: Container) => (container as ComboBox);

  asTab = (container: Container) => (container as Tab);

  asSection = (container: Container) => (container as Section);

  asTextField = (container: Container) => (container as TextField);

  asDateTimeTextField = (container: Container) => (container as DateTimeTextField);

  asTextArea = (container: Container) => (container as TextArea);

  asTextAreaContainer = (container: Container) => (container as TextAreaContainer);

  copyComboBoxItemForAllInList(comboBoxItemToCopy: ComboBoxItem, terminalList: TerminalList | undefined, containerId: string) {
    if (terminalList && terminalList.containerList) {
      try {
        terminalList.containerList
          .forEach(group => group.containerList?.forEach
          (row => row.containerList?.forEach
          (input => {
              //Eine unschöne Art, um dafür zu sorgen, dass nur die ComboBoxen kopiert werden, die in der gleichen Gruppe sind
              // Die Id wird gesplittet und der größte gemeinsame Nenner wird genommen
              let inputidSections = input.containerId.split('.');
              let inputidBisZurDrittenId = inputidSections[0] + '.' + inputidSections[1] + '.' + inputidSections[2];
              if (containerId.includes(inputidBisZurDrittenId)) {
                // console.log('inputidBisZurDrittenId: ' + inputidBisZurDrittenId);
                // console.log('containerId: ' + containerId);
                this.asComboBox(input).selectedItem = JSON.parse(JSON.stringify(comboBoxItemToCopy));
              }
            }
          )))
        //So ne Art Callback
        this.copyComboBoxItemEvent.emit(true);
      } catch (e) {
        console.log(e);
        this.copyComboBoxItemEvent.emit(false);
      }
    }
  }


}
