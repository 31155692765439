<div class="sections-container">
  <div class="section" [style]="isMobileScreen() ? null : section?.inlineStyle"
       *ngFor="let section of tab.containerList">
    <app-divider *ngIf="sectionHasDivider(section)"></app-divider>
    <div *ngIf="section.containerType !== 'Tab'">
    <h3>{{ section.caption }}</h3>
    <div class="input-component-container">
      <!-- INPUT KOMPONENTE -->
      <ng-container *ngFor="let container of section.containerList">
        <div class="terminal-list-row-item-input" *ngIf="layoutElementUtil.isTerminalLabel(container)">
          <app-terminal-label [label]="container"></app-terminal-label>
        </div>
        <div class="input-component" *ngIf="layoutElementUtil.isTextArea(container)">
          <app-text-area [textArea]="layoutElementUtil.asTextArea(container)"></app-text-area>
        </div>

        <div class="input-component-textarea-container" *ngIf="layoutElementUtil.isTextAreaContainer(container)">
          <app-text-area-container [textAreaContainer]="layoutElementUtil.asTextAreaContainer(container)"></app-text-area-container>
        </div>

<!--        <div class="input-component videotext" *ngIf="layoutElementUtil.isVideoTextArea(container)">-->
<!--          <app-video-text-area [videoTextArea]="container"></app-video-text-area>-->
<!--        </div>-->
        <div *ngIf="layoutElementUtil.isComboBox(container)">
          <app-combo-box [comboBox]="layoutElementUtil.asComboBox(container)"></app-combo-box>
        </div>
        <div class="input-component" *ngIf="layoutElementUtil.isTerminalList(container)">
          <app-terminal-list
            [style]="container.inlineStyle ? layoutElementUtil.translateStyle(container.inlineStyle) : null"
            [terminalList]="container"></app-terminal-list>
        </div>
        <div class="input-component field" *ngIf="layoutElementUtil.isTextField(container)">
          <app-text-field [textField]="layoutElementUtil.asTextField(container)"></app-text-field>
        </div>
        <div class="input-component field" *ngIf="layoutElementUtil.isDateTimePicker(container)">
          <app-datetime-picker [showDefaultTime]="true"
                               [(date)]="layoutElementUtil.asDateTimeTextField(container).dateContent"></app-datetime-picker>
        </div>
        <div class="input-component" *ngIf="layoutElementUtil.isFileUpload(container)">
          <app-file-upload [fileUpload]="container"></app-file-upload>
        </div>

      </ng-container>
    </div>
    </div>
  </div>
</div>
