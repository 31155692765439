<div class="file-upload-container">
  <!--Dateiauswahl, click() => öffnet den hidden #fileInput -->
  @if (selectedFiles.length === 0) {
    <button (click)="fileInput.click()" mat-stroked-button class="file-upload-btn">
      <mat-icon color="primary" class="file-upload-icon">attach_file</mat-icon>
      {{ fileUpload?.caption }}
    </button>
  }

  @if (selectedFiles.length > 0) {
    <button mat-stroked-button class="file-upload-btn" (click)="uploadFiles()">
      <mat-icon color="accent" class="file-upload-icon">file_upload</mat-icon>
      Starte Upload
    </button>
  }

  <!-- Anzeige der ausgewählten Dateien -->
  @if (!fileInfosWrapper) {
    <mat-list class="fileinfo-list">
      <p matSubheader>Ausgewählte Dateien: {{ selectedFiles.length }}</p>
      @for (file of selectedFiles; track file.name) {
        <mat-list-item>
          <mat-icon (click)="removeSelectedFile(file)" color="accent" style="vertical-align: bottom" matlistitemicon>
            cancel
          </mat-icon>
          {{ file.name }}
        </mat-list-item>
      }
    </mat-list>
  }


  <!-- Anzeige der hochgeladenen Dateien -->
  @if (fileInfosWrapper) {
    @if (fileInfosWrapper.data$ | async; as fileInfos) {
      <mat-list class="fileinfo-list">
        <p matSubheader>Hochgeladene Dateien: {{ fileInfos.length }}</p>
        @for (file of fileInfos; track $index) {
          <mat-list-item>
            <mat-icon (click)="deleteFile(file)" style="color: red" matlistitemicon>delete</mat-icon>
            <a (click)="downloadFile(file)" onclick="return false" href="#">{{ file.fileName }}</a>
          </mat-list-item>
        }
      </mat-list>
    } @else {
      <app-loading-error [loadingWrapper]="fileInfosWrapper"
                         [message]="'Fehler bei Upload der Dateien'">
      </app-loading-error>
    }
  }

  <!-- Der eigentliche FileInput. Ist aber hidden, weil er hässlich & nicht stylebar ist -->
  <input hidden class="file-upload-input" type="file" #fileInput id="uploadFile" (change)="selectFile($event)"
         name="uploadFile"
         multiple="multiple" accept="application/pdf">
</div>



