@if (comboBox) {
  <mat-form-field color="primary" class="wetter-symbol-combo-box"
                  appearance="outline">
    <mat-label>{{ comboBox.caption }}</mat-label>
    <mat-select (keydown)="filterItems($event)" (focusout)="filterItems($event)" class="wetter-symbol-combo-box-select"
                [(ngModel)]="comboBox.selectedItem"
                (ngModelChange)="comboBox.selectedItem = $event; comboBox.selectedItem.selected = true">

      <!--Der mat-select-trigger setzt die "custom" Auswahl inklusive Icon -->
      <mat-select-trigger *ngIf="comboBox.selectedItem">
        {{ comboBox.selectedItem.description }}
        <img class="combobox-item-icon" *ngIf="comboBox.selectedItem.iconUrl" width="28" height="28"
             src="data:image/png;base64,{{comboBox.selectedItem.iconUrl}}" alt="{{comboBox.selectedItem.description}}">

        <mat-icon class="combobox-item-icon" *ngIf="!comboBox.selectedItem.iconUrl"
                  [style]="'color:'+ comboBox.selectedItem.iconColor">{{ comboBox.selectedItem.iconName }}
        </mat-icon>

      </mat-select-trigger>
      <!-- Die eigentlichen Items wird hier gerendert -->
      @if (comboBoxItemsWithFilter$ | async; as comboBoxItems) {
        <mat-option *ngFor="let item of comboBoxItems; trackBy: trackByFn"
                    [value]="item">

          {{ item.code }} - {{ item.description }}
          <img class="combobox-item-icon" *ngIf="item.iconUrl" width="28" height="28"
               [src]="'data:image/png;base64,'+item.iconUrl"
               alt="{{item.description}}">

          <mat-icon class="combobox-item-icon" [style]="'color:'+ item.iconColor"
                    *ngIf="!item.iconUrl">{{ item.iconName }}
          </mat-icon>
        </mat-option>
      }
    </mat-select>
    <!-- Kopierfunktion -->
    <span matSuffix *ngIf="comboBox?.copyItem">
    <button (click)="$event.stopPropagation();copy()" mat-icon-button
            class="copy-value-btn mat-elevation-z0"
            color="primary">
      <mat-icon>low_priority</mat-icon>
    </button>
  </span>
    <mat-hint class="hint-text" *ngIf="!comboBox.selectedItem.selected">Bitte treffe eine Auswahl!</mat-hint>

  </mat-form-field>
}
