<mat-form-field class="timepicker-container" color="primary" appearance="outline">
  <mat-label>Wähle ein Datum</mat-label>
  <input matInput [ngxMatDatetimePicker]="picker" (click)="onDatePickerClick()" [(ngModel)]="date" [disabled]="disabled" (input)="onChange($event)">
  <mat-hint>dd.MM.yyyy, HH:mm</mat-hint>
  <ngx-mat-datepicker-toggle style="color: #2196f3" matSuffix [for]="picker"></ngx-mat-datepicker-toggle>
  <ngx-mat-datetime-picker (closed)="applyDateChange()" color="primary" #picker
                           [showSpinners]="true" [showSeconds]="false"
                           [stepHour]="1" [stepMinute]="5">
    <ngx-mat-datepicker-actions>
      <button mat-icon-button ngxMatDatepickerApply>
        <mat-icon color="primary">done</mat-icon> <!-- Haken Icon  -->
      </button>
    </ngx-mat-datepicker-actions>
  </ngx-mat-datetime-picker>
</mat-form-field>

<div *ngIf="showClearButton && !disabled" class="clear-button-container">
  <button color="primary" class="clear-button" (click)="date = undefined; applyDateChange()" mat-stroked-button>
    <mat-icon color="accent" class="clear-icon" matPrefix>clear_all</mat-icon>
    Datum löschen
  </button>
</div>
