<mat-form-field [style]="textField.inlineStyle" floatLabel="auto" class="textbox"
                appearance="outline">
  <mat-label>{{textField.caption}}</mat-label>
  <input class="textfield-input"
         [placeholder]="textField.caption"
         [type]="inputType"
         [maxlength]="textField.maxChars === 0 ? null : textField.maxChars!"
         [max]="textField.maxChars === 0 ? null : textField.maxChars!"
         [(ngModel)]="textField.content"
         (ngModelChange)="onInputChange($event)"
         (keydown)="onKeyPress($event)"
         matInput/>
  <span class="suffix" matSuffix>{{textField.fieldSuffix}}</span>
  <span class="prefix" matPrefix>{{textField.fieldPrefix}}</span>

  <app-character-counter *ngIf="textField.maxCharsWarning && textField.maxChars"
                         [maxChars]="textField.maxChars"
                         [position]="'below'"
                         [charSequenceLength]="textField!.content!.toString().length"
                         [maxCharsWarning]="textField.maxCharsWarning"></app-character-counter>
  <mat-hint class="hint-text" *ngIf="isInvalid()">{{errorMessage}}</mat-hint>
</mat-form-field>


