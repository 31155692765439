import {Component, EventEmitter, Input, Output} from '@angular/core';
import {TextArea, TextAreaContainer} from "../../../../model/layout.model";
import {TextAreaComponent} from "../text-area/text-area.component";
import {LayoutElementUtil} from "../../../../service/layout/layout-element.service";
import {NgForOf, NgIf} from "@angular/common";
import {TerminalLabelComponent} from "../terminal-label/terminal-label.component";
import {CharacterCounterComponent} from "../character-counter/character-counter.component";

@Component({
  selector: 'app-text-area-container',
  standalone: true,
  imports: [
    NgForOf,
    NgIf,
    TerminalLabelComponent,
    TextAreaComponent,
    CharacterCounterComponent
  ],
  templateUrl: './text-area-container.component.html',
  styleUrl: './text-area-container.component.scss'
})
export class TextAreaContainerComponent {

  @Input() textAreaContainer!: TextAreaContainer;
  charCount:number = 0;

  constructor(public layoutElementUtil: LayoutElementUtil) {

  }

  computeCharcount(count:number) {
    this.charCount = this.charCount + count;
  }

}
