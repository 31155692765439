export const gatewayUrl = "https://dmx4-api-gateway-prod.gcp.cloud.hr.de"

export const environment = {
  production: true,
  enableAuth: true,
  terminalApiUrl: `${gatewayUrl}/api/wetter/terminal`,
  messagingApiUrl: `${gatewayUrl}/api/wetter/messaging/terminal`,
  auth: {
    clientId: 'fb20fde3-1fa0-43c7-8e9c-fca7e7b29073', // Application (client) ID from the app registration
    authority: 'https://login.microsoftonline.com/daaae532-0750-4b35-8f3f-fdd6ba4c86f0', // The Azure cloud instance and the app's sign-in audience (tenant ID, common, organizations, or consumers)
    redirectUri: 'https://wetter-terminal.gcp.cloud.hr.de',
    scope: ['api://3cb3e179-b17f-4ffe-a3ce-1c0127597a18/access_as_user']
  },
  firebaseConfig: {
    apiKey: "AIzaSyBrPtIq_F_fqeQkXRokB1kXW_Q4-KhfyME",
    authDomain: "wetter-dts-prod-1292986962.firebaseapp.com",
    databaseURL: "https://wetter-dts-prod-1292986962.firebaseio.com",
    projectId: "wetter-dts-prod-1292986962",
    storageBucket: "wetter-dts-prod-1292986962.appspot.com",
    messagingSenderId: "197853491905",
    appId: "1:197853491905:web:e0689bdf765d69dec21a40",
    vapidKey: "BBEO8JXArNhIDQ2vIVCbG3CsdMOsCq6675ylZNELp4mgysPxiTMVNKnB8fgFZ5q16IWoFC8soZMiDNzMg3eMbJ8"
  }
};
