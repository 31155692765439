import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {TextArea} from "../../../../model/layout.model";
import { CharacterCounterComponent } from '../character-counter/character-counter.component';
import { NgIf } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import {LayoutElementUtil} from "../../../../service/layout/layout-element.service";

@Component({
    selector: 'app-text-area',
    templateUrl: './text-area.component.html',
    styleUrls: ['./text-area.component.scss'],
    standalone: true,
    imports: [MatFormFieldModule, MatInputModule, FormsModule, NgIf, CharacterCounterComponent]
})
export class TextAreaComponent implements OnInit {

  @Input() textArea!: TextArea;
  @Input() stopEditing:boolean = false;
  @Output() diff = new EventEmitter<number>();

  constructor(public layoutElemeneUtil: LayoutElementUtil) {

  }

  ngOnInit(): void {
    this.fire(this.textArea.content);
  }

  fire(newObj: any): void {
    if(this.stopEditing) {
      this.textArea.maxChars = this.textArea.content?.length;
    } else {
      let contentLength: number = 0;
      if (this.textArea.orgContent && this.textArea.orgContent != undefined) {
        contentLength = this.textArea.orgContent.length;
      }
      let d = newObj.length - contentLength;
      //console.log("NEWOBJ: " + newObj + " TEXTAREA_CONTENT: " + this.textArea.orgContent);

      this.diff.emit(d);
      this.textArea.orgContent = newObj;
    }
  }
}
