import {Component, EventEmitter, Input, Output} from '@angular/core';
import {Container, TerminalList} from "../../../../model/layout.model";
import {LayoutElementUtil} from "../../../../service/layout/layout-element.service";
import {ScreenSizeObserverService} from "../../../../service/layout/screen-size-observer.service";
import { TerminalLabelComponent } from '../terminal-label/terminal-label.component';
import { ComboBoxComponent } from '../combo-box/combo-box.component';
import { TextFieldComponent } from '../text-field/text-field.component';
import { NgFor, NgIf } from '@angular/common';

@Component({
    selector: 'app-terminal-list',
    templateUrl: './terminal-list.component.html',
    styleUrls: ['./terminal-list.component.scss'],
    standalone: true,
    imports: [NgFor, NgIf, TextFieldComponent, ComboBoxComponent, TerminalLabelComponent]
})
export class TerminalListComponent {

  @Input() terminalList?: TerminalList;

  constructor(public layoutElementUtil: LayoutElementUtil) {
  }

}
